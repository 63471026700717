import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import * as thunks from './thunks';

const SLICE_NAME = 'calculateTipOutsDrawer';

export const initialState = fromJS({
  selectedPayPeriod: [],
  tipInsLoading: false,
  tipOutsLoading: false,
  tipInsData: {},
  tipOutsData: {},
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: builder => {
    builder
      .addCase(thunks.fetchTipInsData.pending, (state, action) =>
        state
          .set('tipInsLoading', true)
          .set('selectedPayPeriod', [
            action.meta.arg.startDate,
            action.meta.arg.endDate,
          ])
          .set('tipInsData', fromJS({}))
      )
      .addCase(thunks.fetchTipInsData.fulfilled, (state, action) =>
        state
          .set('tipInsLoading', false)
          .set('tipInsData', fromJS(action.payload))
      )
      .addCase(thunks.fetchTipInsData.rejected, (state, action) =>
        state
          .set('tipInsLoading', false)
          .set('tipInsData', fromJS(action.payload))
      )
      .addCase(thunks.fetchTipOutsData.pending, (state, action) =>
        state
          .set('tipOutsLoading', true)
          .set('selectedPayPeriod', [
            action.meta.arg.startDate,
            action.meta.arg.endDate,
          ])
          .set('tipOutsData', fromJS({}))
      )
      .addCase(thunks.fetchTipOutsData.fulfilled, (state, action) =>
        state
          .set('tipOutsLoading', false)
          .set('tipOutsData', fromJS(action.payload))
      )
      .addCase(thunks.fetchTipOutsData.rejected, (state, action) =>
        state
          .set('tipOutsLoading', false)
          .set('tipOutsData', fromJS(action.payload))
      );
  },
});

export const { actions, reducer } = slice;
